<script lang="ts" setup>
const RESEND_EMAIL_TIMEOUT_MILLISECONDS = 599_000;
const { t } = useT();
const { open } = useTaoModals();
const { emailVerificationTimerCookie } = useEmailVerificationTimerCookie();
const { duration, durationLeft, reset } = useCountdown({
	timestamp: Number(emailVerificationTimerCookie.value) + RESEND_EMAIL_TIMEOUT_MILLISECONDS,
	formatToken: "mm[ : ]ss"
});
const handleRequestEmailConfirm = () => {
	if (duration.value > 0) {
		return;
	}

	dispatchGAEvent({
		event: "verification",
		location: "header",
		step: "info",
		type: "email"
	});
	open("LazyOModalEmailConfirm");
};

watch(emailVerificationTimerCookie, (value) => {
	if (value) {
		reset(Number(emailVerificationTimerCookie.value) + RESEND_EMAIL_TIMEOUT_MILLISECONDS);
	}
});
</script>
<template>
	<div
		class="email-notify d-flex align-items-center justify-content-center gap-2 px-3 px-xl-7 py-2"
		:class="{ 'is-sent': duration > 0 }"
		@click="handleRequestEmailConfirm"
	>
		<NuxtIcon name="20/error" />
		<AText as="div" type="base">
			<i18n-t keypath="An email was sent to you. Click the {key1} in your inbox to complete the sign-up! {key2}">
				<template #key1>
					{{ t("verification link") }}
				</template>
				<template #key2>
					<AText v-if="duration > 0" type="base" class="resend" :modifiers="['inheritSize']">
						{{ t("Resend") }} ({{ durationLeft }})
					</AText>
					<AText v-else type="base" class="resend" :modifiers="['underline']">{{ t("Click to resend!") }}</AText>
				</template>
			</i18n-t>
		</AText>
	</div>
</template>
<style lang="scss" scoped>
.email-notify {
	background-color: var(--alternative-corall);
	z-index: 3;

	&:not(.is-sent) {
		&:hover {
			cursor: pointer;
			opacity: 0.85;
		}
	}

	.nuxt-icon {
		font-size: 24px;
	}
}
</style>
